import React from "react"
import styled from "styled-components"
import { Button, Line } from "../components/elements"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { HeaderPage, Services } from "../components/structure"
import { CatchyHeadings } from "../components/typography"
import faq from "../images/stats/faq.svg"

export default function FAQ() {
  return (
    <Layout>
      <SEO title="FAQ" />
      <HeaderPage
        src={faq}
        color={props => props.theme.color.complimentry}
        bgColor={props => props.theme.color.bgFirst}
        title="Read our frequently asked questions"
        catchy="FAQ"
      />
      <Wrapper>
        <CatchyHeadings>Frequently Asked Questions</CatchyHeadings>
        <Cover>
          <Question>1. What is digital marketing?</Question>
          <Answer>
            Digital marketing is the act of promoting a company’s services and
            products to its audience by using digital technologies. The most
            commonly used digital marketing platforms include websites, search
            engines, social media, and email marketing.
          </Answer>
        </Cover>
        <Cover>
          <Question>2. Do I need digital marketing?</Question>
          <Answer>
            In this day and age, digital marketing is a valuable tool for any
            business. If your clients and customers are online, then you should
            be too. There are endless benefits of going digital, including:
            <ListCover>
              <List>Real-time customer engagement</List>
              <List>Cheaper costs</List>
              <List>AbiListty to track data</List>
              <List>More exposure with fewer restrictions</List>
              <List>Content that is more shareable</List>
            </ListCover>
          </Answer>
        </Cover>
        <Cover>
          <Question>3. What is eCommerce?</Question>
          <Answer>
            eCommerce refers to a digital marketplace, or buying and selling
            goods over the internet. Businesses who engage in eCommerce
            typically setup online stores to sell their goods and services.
          </Answer>
        </Cover>
        <Cover>
          <Question>4. Does my business need to be on social media?</Question>
          <Answer>
            As a business, having a social media presence comes with too many
            advantages to ignore. With an official profile on a platform such as
            Facebook or Instagram, you have a better chance of reaching a
            specific audience, building brand loyalty, positively interacting
            with your customers, and driving traffic to your website
          </Answer>
        </Cover>
        <Cover>
          <Question>5. What should I post on social media?</Question>
          <Answer>
            The best social media content follows the rule of the four Es. It
            educates, empowers, entertains, and engages your audience. When
            coming up with social media posts, always focus on your voice, your
            message, and your audience instead of your competition.
          </Answer>
        </Cover>
        <Cover>
          <Question>
            6. I know nothing about technology. Should I still use a digital
            agency?
          </Question>
          <Answer>
            Yes! Our tech-savvy team of experts will take care of all your
            digital needs so you can focus on providing the products and
            services your customers love.
          </Answer>
        </Cover>
        <Cover>
          <Question>7. What is SEO?</Question>
          <Answer>
            SEO refers to search engine optimisation. Put simply, this is the
            process of using different strategies to help search engines
            recognise and rank your website. Quality SEO can significantly
            increase your website traffic.
          </Answer>
        </Cover>
        <Cover>
          <Question>8. What is PPC?</Question>
          <Answer>
            PPC refers to pay-per-click. As a business you can buy web traffic
            by paying a fee every time someone clicks on one of your
            advertisements. Google Ads is one of the most popular forms of PPC.
          </Answer>
        </Cover>
        <Cover>
          <Question>9. Do I need to update my website?</Question>
          <Answer>
            Most of the time, just having a great website isn’t enough. You also
            need to update your website with new content to keep your customers
            engaged
          </Answer>
        </Cover>
        <Button to="/contact/">Contact now</Button>
      </Wrapper>
      <Line />
      <Services />
    </Layout>
  )
}
const Question = styled.h2`
  font-size: 2.4rem;
  line-height: 1.5;
  color: ${props => props.theme.color.dark};
`
const Answer = styled.p`
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.5;
  color: ${props => props.theme.color.dark};
`
const ListCover = styled.div`
  padding: 0.5rem 0 0 1rem;
`
const List = styled.li`
  padding: 0.5rem 0 0 0.5rem;
`
const Cover = styled.div`
  padding: 1rem 0;
`
const Wrapper = styled.div`
  width: 65%;
  height: auto;
  margin: 0 auto;
  padding: 2rem 0;
  @media only screen and (max-width: 75em) {
    width: 65%;
  }
  @media only screen and (max-width: 56.25em) {
    width: 65%;
  }
  @media only screen and (max-width: 37.5em) {
    width: 95%;
  }
  @media only screen and (min-width: 112.5em) {
    width: 55%;
  }
`
